.product-navbar {
   
    background: linear-gradient(54deg, rgba(17,19,178,0.16) 0%, rgba(249,18,221,0.15) 100%);
}

.product_cards_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;  
    justify-items: center;
    padding: 50px;
}

.product_card {
    width: 100%;
    max-width: 420px; 
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(150px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;  position: relative; 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product_card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

.product_card_image {
    width: 100%;
    height: 220px;  
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 10px;
}

.product_card_title {
    font-size: 18px;
    font-weight: 500;
    color: #394c54;
    text-align: center;
    margin-bottom: 10px;
}

.product_card_description {
    font-size: 16px;
    color: #6A6A6A;
    text-align: center;
    margin-bottom: 35px;
}

.read_more__ {
    display: inline-block;
    padding: 10px 20px;
    background: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, #e254ff, #57d3ff);
    color: transparent;
    font-size: 16px;
    border: none;
    font-weight: bold;
    text-decoration: none;
    margin-top: 20px;
    transition: all 0.3s ease;
    width: fit-content;
  }
  
  .read_more__:hover {
    border: 2px solid transparent;
    background-image: linear-gradient(90deg, #e254ff, #57d3ff);
    color: white;
    border: none;
    background-clip: border-box;
    border-image: linear-gradient(90deg, #e254ff, #57d3ff) 1;
    border-radius: 20px;
  }
  
  .read_more__::after {
    content: "➔";
    padding-left: 5px;
  }
  








.read_more__{
    position: absolute;  
    bottom: 1px; 
    right: 1px; }


@media (max-width: 900px) {
    .product_cards_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .product_cards_container {
        grid-template-columns: 1fr;
    }

    .product_card_image {
        height: 180px; /* Adjust height for smaller screens */
    }
}

.footer-container {
    width: 100%;  
    background: #F0F3F7;
    padding-top: 25px;
    margin-bottom: 5vh;
}

.footer {
    padding: 2px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
}

.footer-content {
    max-width: 1200px;
    width: 100%;
}

.footer-section {
    display: flex;
    flex-wrap: wrap;
}

.footer-column {
    flex: 1;
    min-width: 150px;
    color: black;
    margin: 1px; 
}

.footer-column img {
    margin-bottom: 15px;  
}

.footer-column h5 {
    font-size: 1.2rem;
    margin-bottom: 15px;  
    text-transform: uppercase;
}

.footer-column p {
    font-size: 1rem;
    line-height: 1.5;
}

.footer-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-list li {
    margin-bottom: 8px; 
}

.footer-list li a {
    color: black;
    text-decoration: none;
}

.footer-list li a:hover {
    text-decoration: underline;
}

.paragraph2 {
    letter-spacing: 10px; 
    font-size: larger; 
    margin-top: 0;  
    margin-bottom: 5px;  
}

.newsletter-input {
    padding: 10px;
    width: calc(100% - 22px);
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 20px;
}

.services {
    cursor: pointer;
}

.services:hover {
    text-decoration: underline;
}

.newsletter-button {
    padding: 10px;
    background-color: #42E8E0;
    color: white;
    border: none;
    cursor: pointer;
}

.newsletter-button:hover {
    background-color: #3FD3D5;
}

.footer-copyright {
    text-align: center;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-top: 20px;
}

.footer-copyright a {
    color: white;
    text-decoration: none;
}

.footer-copyright a:hover {
    text-decoration: underline;
}

.mail {
    color: inherit;  
    font-size: 1.8rem; 
    text-decoration: none;  
}

.second {
    margin-left: 8vw;
}

.email-input-container {
    display: flex;
    align-items: center;  
}

.newsletter-input {
    padding: 10px;
    width: calc(100% - 30px);  
    border: 1px solid black;
    border-radius: 20px;
    min-width: fit-content;
    border: 2px solid #c654ff; 
}

.newsletter-input::placeholder {
    color: black; 
    opacity: 1;    
}

.arrow {
    color: black;  
    cursor: pointer; 
    font-size: 1.5rem;  
    font-weight: 900;  
    margin-left: -25px; 
    margin-bottom: 15px;  
    position: inherit;  
}

.mail:hover {
    text-decoration: underline; 
}

/* Responsive Design */
@media (max-width: 1024px) {
    .footer {
        margin-left: 5%;
    }

    .footer-column {
        flex-basis: 45%; 
        margin-bottom: 20px;  
    }

    .newsletter-input {
        width: calc(100% - 30px); 
    }
}

@media (max-width: 768px) {
    .footer {
        margin-left: 0;
        align-items: center;
    }

    .footer-column {
        flex-basis: 100%;
        text-align: center;
    }

    .email-input-container {
        flex-direction: column;
    }

    .newsletter-input {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 10px;
    }

    .footer-column {
        margin-bottom: 15px;
    }

    .paragraph2 {
        font-size: larger;
        letter-spacing: 5px;
    }
}

.card {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 90%;
  height: 250px;
  position: relative;
  overflow: hidden;
}

.card-image {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.card-title {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease;
}

.card-description {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.card:hover .card-image {
  transform: translateY(-10px);
}

.card:hover .card-title {
  transform: translateY(-10px);
}

.card:hover .card-description {
  opacity: 1;
  transform: translateY(-10px);
}

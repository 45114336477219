.mobileapps_card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;  
    gap: 12px 1px; 
    justify-items: center;
}

.mobileapps_card {
    width: 100%;
    max-width: 280px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(150px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mobileapps_card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

.mobileapps_card_image {
    width: 100%;
    height: 90%;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid rgba(238, 174, 202, 1);
}

.mobileapps_card_title {
    font-size: 18px;
    font-weight: 500;
    color: #394c54;
    text-align: center;
}

@media (max-width: 900px) {
    .mobileapps_card_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .mobileapps_card_container {
        grid-template-columns: 1fr;
    }
}

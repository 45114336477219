.finework_main_container {
    text-align: center;  
    padding: 50px; 
    background-color: #f9f9f9;  background: rgba(238, 174, 202, 0.523);
    background: linear-gradient(103deg, rgba(238, 174, 202, 0.388) 0%, rgba(148, 188, 233, 0.402) 100%);
}

.finework_heading {
    font-size: 40px;  
    font-weight: bold;  
    margin-bottom: 50px;  
    color: #394c54;  
}

.finework_card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 20px 10px;  
    justify-items: center;   
}

.finework_card {
    width: 100%;
    max-width: 280px;   
    background: rgba(255, 255, 255, 0.2);   
    border-radius: 15px;
    backdrop-filter: blur(150px);   
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    padding: 15px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.finework_card:hover {
    transform: translateY(-10px);   
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);   
}

.finework_card_image {
    width: 100%;  
    height: 90%; 
    border-radius: 10px;   
    object-fit: cover;   
    margin-bottom: 10px;
    border: 2px solid rgba(238, 174, 202, 1);  
}

.finework_card_title {
    font-size: 18px;   
    font-weight: 500;  
    color: #394c54;  
    text-align: center; 
}

/* Responsive behavior */
@media (max-width: 900px) {
    .finework_card_container {
        grid-template-columns: repeat(2, 1fr);   
    }
}

@media (max-width: 600px) {
    .finework_card_container {
        grid-template-columns: 1fr;   
    }
}

/* Default desktop styles */
.section3_container {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding: 40px; 
    border: none;
    outline: none;
    border-radius: 10px;  
    margin-left: 85px; 
}

.section3_text {
    flex: 1; 
    z-index: 10;
    color: rgb(57, 76, 84); 
    margin-right: 20px;  
}

.section3_title {
    font-size: 70px; 
    font-weight: bold; 
    z-index: 10;
    margin-bottom: 10px; 
}

.section3_subtitle {
    font-size: 35px; 
    z-index: 10;
    font-weight: 400;  
    margin: 10px 0; 
}

.section3_description, 
.section3_support_description, 
.section3_integrations_description {
    margin: 5px 0; 
    z-index: 10;
}

.section3_support_title, 
.section3_integrations_title {
    font-size: 35px; 
    font-weight: 400; 
    z-index: 10;
    margin: 15px 0 5px 0; 
}

.section3_image_container {
    flex-shrink: 0; 
    width: 50%;  
    position: relative;  
}

.section3_image {
    margin-left: 0%;
    object-fit: cover;  
    border-radius: 10px;   
}

.pwaei {
    font-size: 70px;
    z-index: 10;
    font-weight: bold;
}

/* Add responsiveness for different screen sizes */

/* For tablets (768px and above) */
@media (max-width: 1024px) {
    .section3_container {
        flex-direction: column;
        padding: 20px;
        margin-left: 20px;
    }

    .section3_text {
        margin-right: 0;
        text-align: center;
    }

    .section3_image_container {
        width: 80%; 
        margin-top: 20px;
    }

    .section3_title {
        font-size: 50px; 
    }

    .section3_subtitle {
        font-size: 30px;
    }

    .section3_support_title, 
    .section3_integrations_title {
        font-size: 30px;
    }
}

/* For mobile devices (below 768px) */
@media (max-width: 768px) {
    .section3_container {
        flex-direction: column;
        padding: 10px;
        margin-left: 0;
    }

    .section3_text {
        text-align: center;
    }

    .section3_title {
        font-size: 40px;
    }

    .section3_subtitle {
        font-size: 25px;
    }

    .section3_support_title, 
    .section3_integrations_title {
        font-size: 25px;
    }

    .section3_image_container {
        width: 100%;
    }

    .section3_image {
        width: 100%;
        height: auto; 
        margin-left: 0;
    }

    .pwaei {
        font-size: 50px;
    }
}

.main-contact {
     
    background: radial-gradient(circle, rgba(186, 28, 161, 0.15) 32%, rgba(255, 255, 255, 1) 100%);
    min-height: 100vh;
    animation: fadeIn 1s ease-in-out;
}

.contact-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;gap: 50px;
    padding: 0 50px;
    opacity: 0;
    transform: translateY(30px);
    animation: slideUp 1s ease-in-out forwards;
}

.contact-left {
    flex: 2;
    color: #6A6A6A;
    margin-right: 20px;
}

h2 {
    color: #6A6A6A;
    font-weight: 800;
    font-size: 4.5rem;
    margin-bottom: 30px;
    animation: textFadeIn 1.2s ease-in-out;
}

.message-form {
    display: flex;
    flex-direction: column;
}

.message-box {
    font-size: 1.2rem;
    color: #6A6A6A;
    background-color: #f9f9f9;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    resize: vertical;
    animation: inputExpand 0.8s ease;
}

.send-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    background-color: #6A6A6A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 90px;
    transition: background-color 0.3s ease;
    animation: buttonPop 1.5s ease-in-out;
}

.send-button:hover {
    background-color: #555;
}

.contact-right {
    flex: 1;
    margin-top: 50px;
    color: #333;
    opacity: 0;
    transform: translateY(30px);
    animation: slideUp 1s ease-in-out forwards;
    animation-delay: 0.5s;
}

.contact-right h3 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact-right p {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes textFadeIn {
    from {
        opacity: 0;
        transform: translateX(-30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes inputExpand {
    from {
        width: 0;
        opacity: 0;
    }
    to {
        width: 100%;
        opacity: 1;
    }
}

@keyframes buttonPop {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 0 20px;
    }

    h2 {
        font-size: 3rem;
    }

    .contact-left, .contact-right {
        flex: none;
        margin: 20px 0;
    }
}

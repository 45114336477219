/* Testimonials Section */
.testimonials_container {
    text-align: center;
    padding: 50px;
    color: #394c54;
    background: rgb(0,154,228);
    background: radial-gradient(circle, rgba(0,154,228,0.09576330532212884) 0%, rgba(255,0,232,0.0985644257703081) 100%);
}

.testimonials_heading {
    font-size: 30px;
    font-weight: bold;
    color: #394c54;
}

.testimonials_description {
    margin: 20px 0;
    font-size: 18px;
    color: #555;
}

.logos_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin: 20px 0;
    margin-bottom: 80px;
}

.logo_color {
    font-size: 80px;
    transition: transform 0.3s;
}

.logo_color:hover {
    transform: scale(1.1);
}

.node {
    color: #8CC84B;
}

.next {
    color: #000000;
}

.react {
    color: #61DAFB;
}

.mongodb {
    color: #4DB33D;
}

.mysql {
    color: #00648C;
}

.flutter {
    color: #02569B;
}

.testimonial_heading {
    font-size: 30px;
    letter-spacing: 1px;
    word-spacing: 4px;
    font-weight: bold;
    margin: 30px 0;
}

.testimonials {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.testimonial {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: left;
    flex: 1 1 300px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.client_info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.client_photo {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    transition: transform 0.3s;
}

.client_photo:hover {
    transform: scale(1.1);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.testimonial {
    animation: fadeInUp 0.5s ease forwards;
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonials_heading {
        font-size: 24px;
    }

    .testimonials_description {
        font-size: 16px;
    }

    .logos_container {
        gap: 20px;
    }

    .logo_color {
        font-size: 60px;
    }

    .testimonial {
        max-width: 100%;
        flex: 1 1 100%;
    }

    .client_photo {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 480px) {
    .testimonials_heading {
        font-size: 20px;
    }

    .testimonials_description {
        font-size: 14px;
    }

    .logos_container {
        gap: 15px;
    }

    .logo_color {
        font-size: 50px;
    }

    .testimonial {
        padding: 15px;
    }

    .client_photo {
        width: 30px;
        height: 30px;
    }
}

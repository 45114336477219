/* Navbar Styles */
.main-navbar {
  height: 95px;
  width: 100vw;
  background: linear-gradient(54deg, rgba(194, 228, 236, 0.134) 0%, rgba(254, 254, 254, 0.146) 100%);
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
  background: white;
  justify-content: space-between;
}

.logo img {
  height: 50px;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.links {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
}

.links a {
  text-decoration: none;
  color: #454545;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 1rem;
}

.call-now {
  padding: 5px 13px;
  background: linear-gradient(90deg, rgba(144, 7, 150, 0.846) 0%, rgba(0, 212, 255, 1) 100%);
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 130px;
}

.social-links {
  display: flex;
  gap: 20px;
  margin-right: 15px;
}

.social-links a {
  font-size: 1.8rem;
  color: #454545;
  transition: color 0.3s ease, transform 0.3s ease;
}

.hamburger {
  display: none;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 768px) {
  .links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 85px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 10;
  }

  .links.active {
    display: flex;
  }

  .hamburger {
    display: block;
    font-size: 24px;
    margin-left: auto;
  }
  .social-links {
    display: flex;
    gap: 20px;
    margin-left: 160px;
  }
  
}


@media (max-width: 460px) {
  .main-navbar {
    height: 80px;
    padding: 0 10px;
  }

  .logo img {
    height: 35px;
  }

 
 
  .social-links {
    display: block;
    margin-left: 20px;

  }

  .hamburger {
    display: block;
    font-size: 24px;  
    margin: 0 10px 0 auto; 
    padding: 0; 
  }

   .social-links {
    display: flex;
    gap: 20px;
    margin-left: 160px;
  }
}
html{
  overflow-x: hidden;
}


@media (max-width: 370px){
 
.main-navbar{
   padding: 2px;
 display: flex;
}

.logo{
  width: 2px;
}
}
.horiz_card_list {
  margin-top: 2%;
  margin-left: 0%;
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px 40px;
  gap: 15px;
  scrollbar-width: thin;
  scrollbar-color: rgba(190, 73, 124, 0.8) white;
  position: relative;
}

.horiz_card_list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.horiz_card_list::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

.horiz_card_list::-webkit-scrollbar-thumb {
  background: rgba(190, 73, 124, 0.8);
  border-radius: 10px;
}

.horiz_card {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 15px;
  border: 2px solid transparent;
  height: fit-content; /* Ensure the card height adjusts based on content */
}

.card_content {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_image {
  width: 300px;
  height: auto; /* Allow the image to adjust in height based on aspect ratio */
  object-fit: cover;
  margin-right: 10px;
}

.read_more {
  display: inline-block;
  padding: 10px 20px;
  background: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #e254ff, #57d3ff);
  color: transparent;
  font-size: 16px;
  border: none;
  font-weight: bold;
  text-decoration: none;
  margin-top: 20px;
  transition: all 0.3s ease;
  width: fit-content;
}

.read_more:hover {
  border: 2px solid transparent;
  background-image: linear-gradient(90deg, #e254ff, #57d3ff);
  color: white;
  border: none;
  background-clip: border-box;
  border-image: linear-gradient(90deg, #e254ff, #57d3ff) 1;
  border-radius: 20px;
}

.read_more::after {
  content: "➔";
  padding-left: 5px;
}

.newdiv {
  margin-left: 12vw;
}

/* Media Queries */
@media (min-width: 1200px) {
  .horiz_card_list {
    gap: 20px;
  }

  .horiz_card {
    max-width: 800px;
  }

  .card_image {
    width: 300px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .horiz_card_list {
    padding: 20px 20px;
    gap: 10px;
  }

  .horiz_card {
    max-width: 650px;
  }

  .card_image {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .horiz_card_list {
    flex-direction: column;
    padding: 10px 10px;
  }

  .horiz_card {
    max-width: 100%;
    flex-direction: column;
  }

  .card_image {
    width: 100%;
    height: auto;
  }

  .card_content {
    padding: 10px;
  }

  .read_more_button {
    width: 100%;
  }
}

.main-container {
    background: rgb(150,7,99);
    background: radial-gradient(circle, rgba(150,7,99,0.036939775910364125) 0%, rgba(0,212,255,0.04254201680672265) 100%);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.gradient-heading {
    background: linear-gradient(288.34deg, #11B9F9 0.43%, #C209CA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 20px;  
    justify-items: center;
    width: 100%;
    max-width: 1400px;  
    margin-top: 20px;
}

.card {
    background-color: white;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); 
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%;
   
    min-width: 320px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    min-height: 400px; 
}

.card img {
    width: 60%;  
    margin: 0 auto;
    border-radius: 10px;
    transition: transform 0.4s ease;
}

 
.card-description {
    opacity: 0;
    transform: translateY(250%);
    transition: opacity 0.4s ease, transform 0.4s ease;
    font-size: 1rem;  
    text-align: center;
    color: #000;  
    position: absolute;
    width: 100%;
    left: 4%;  
    top: 50%;
    transform: translate(-250%, 100%);  
}

 
.card:hover img {
    transform: translateY(-80px) scale(0.4); 
}

.card:hover .card-title {
    transform: translateY(-800px); 
    font-size: 1.2rem;  
}

.card:hover .card-description {
    opacity: 1;  
    transform: translate(-50%, -40%); 
    margin-left: -200px; 
    margin-top: -20px;
}





/* Media Queries */
@media (max-width: 768px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
        margin-right: 2px;
    }
}

@media (max-width: 480px) {
    .cards-container {
        grid-template-columns: 1fr; margin-right: 20px;
    }

    .card {
        height: 500px; 
    }

    .card img {
        width: 80%;
    }
}
.card:hover .card-title {
    
    transform: translateY(-3000px); 
    font-size: 0.2rem;
    /* position: relative;   */
}


.card-title_ {
    position: absolute;
    margin-top: 300px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    transition: transform 0.4s ease, font-size 0.4s ease;
   
}
.card-description {
    opacity: 0;
    transform: translateY(250%);
    transition: opacity 0.4s ease, transform 0.4s ease;
    font-size: 1rem;
    text-align: center;
    color: #000;
    position: absolute;
    width: calc(100% - 40px);  
    left: 8%;
    top: 50%;
    transform: translate(-250%, 100%);
    padding: 0 20px; 
    box-sizing: border-box; 
}

.card:hover .card-description {
    opacity: 1;
    transform: translate(-50%, -40%);
    padding-right: 5px;
    margin-left: -190px;
    margin-top: -20px; 
}

@media (max-width:1180px){
    .card:hover .card-description{
        margin-left: -160px;
    }
}

@media (max-width: 425px) {

    .card-description{
      margin-left: 25px; 
    }

        
}

@media (max-width: 950px) {
    .card{
        display: flex;
        flex-direction: column;
        gap: 1;

    }
    /* .card img{
        width: 25px;
    } */
.card-title_{
font-size: larger;
font-weight: 900;
/* position: relative; */

}
.card-description{
    font-size: medium;
}
}
 
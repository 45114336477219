.rotation-container {
    overflow: hidden;  
    width: 100%;  
    display: flex;
    padding-top: 8vh;
    justify-content: center;  
}

.logo-container {
    display: flex;
    animation: rotate 15s linear infinite;  
    white-space: nowrap;
}

.logo-container img {
    max-width: 105px;  
    max-height: 80px;  
    object-fit: contain;
    margin: 0 20px;  
}

 
@keyframes rotate {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);  
    }
}

/* Optional: Pause rotation on hover */
.rotation-container:hover .logo-container {
    animation-play-state: paused; 
}

.navbarinabout {
    margin-top: 0vh;
}

.contentdiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12vh;
    color: #5D5D5D;
}

.contentdiv h3 {
    font-size: 18px;
    color: #333;
    letter-spacing: 0.5px;
    transition: color 0.3s ease;color: #5D5D5D;
}

.contentdiv h3:hover {
    color: #007bff;
}

.contentdiv h2 {
    font-size: 9vh;
    font-weight: 800;
    color: #333;
    transition: transform 0.3s ease, color 0.3s ease;color: #5D5D5D;
}

.contentdiv h2:hover {
    transform: scale(1.05);
    color: #ff6347;
}

.contentdiv2 {
    display: flex;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background: linear-gradient(103deg, rgba(120, 165, 218, 0.448) 0%, rgba(255, 255, 255, 0.93) 100%);
    border-radius: 15px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12vh;color: #5D5D5D;
}

.text-content {
    flex: 1;
    margin-right: 20px;color: #5D5D5D;
}

.team-title {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;color: #5D5D5D;
}

.team-subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;color: #5D5D5D;
}

.company-description {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;color: #5D5D5D;
}

.ceo-quote {
    font-size: 16px;
    font-style: italic;
    color: #444;
    border-left: 3px solid #007bff;
    padding-left: 10px;color: #5D5D5D;
}

.team-image {
    max-width: 300px;
    height: auto;
    border-radius: 10px;
}

.gradient-text {
    font-size: 48px;
    font-weight: bold;
    background: linear-gradient(149deg, rgba(28,34,186,0.93) 0%, rgba(161,120,218,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.para {
    margin-top: -10px;
    text-align: center;
}

.timeline-section {
    min-height: 80vh; /* Ensuring enough height for smaller screens */
}

.timeline {
    position: relative;
    margin: 40px auto;
    width: 30%;
}

.timeline-event {
    position: relative;
    margin: 60px 0;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;
    background-clip: padding-box;
}

.timeline-event h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.timeline-event p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin-top: 5px;
}

.timeline-line {
    position: absolute;
    left: 50%;
    top: 0;
    height: 0;
    width: 35px;
    background: linear-gradient(172deg, rgba(63, 94, 251, 0.363) 0%, rgba(252, 70, 106, 0.331) 100%);
    transform: translateX(-50%);
    z-index: -1;
    transition: height 3s ease; /* Faster animation */
}

.animate-line {
    height: 100%;
}

@media (max-width: 768px) {
    .contentdiv {
        flex-direction: column;
        align-items: center;
    }

    .contentdiv2 {
        flex-direction: column;
    }

    .team-image {
        max-width: 100%;
    }

    .timeline {
        width: 90%;
    }

    .timeline-line {
        width: 20px; /* Adjust for smaller screens */
    }
}
.contentdiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12vh;
    color: #333;
    max-width: 100%; /* Limit the width */
    margin-left: 50px;
    margin-right: auto; /* Center the content */
    padding: 0 20px;  
}
/* Large screens */

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    /* Stack content vertically */
    .contentdiv, .contentdiv2 {
        flex-direction: column;
        align-items: center;
        margin-top: 6vh;
        padding: 0 15px;
    }

    .left, .right {
        width: 100%;
        text-align:left;
    }

    .contentdiv h2 {
        font-size: 6vh; /* Smaller font size for headings */
    }

    .contentdiv p {
        font-size: 16px;
        line-height: 1.4;
    }

    .team-image {
        max-width: 90%;
        height: auto;
        margin-top: 20px;
    }

    .timeline {
        width: 90%; /* Reduce width */
        margin: 20px auto;
    }

    .timeline-line {
        width: 10px; /* Thinner line */
    }
}
/* @media (max-width: 650px) {
    .right img{
        margin-left: -120px;
        width: 180px;
    }
} */

/* Extra-small screens (mobile) */
@media (max-width: 480px) {
    .contentdiv h2 {
        font-size: 5vh; /* Adjusted font size for mobile */
    }

    .contentdiv2 {
        padding: 10px; /* Reduced padding */
    }

    .team-image {
        max-width: 80%; /* Smaller width for mobile */
    }

    .timeline-event {
        padding: 15px;
        margin: 40px 0;
    }

    .timeline-line {
        width: 8px;
    }
}
.timeline-line {
  position: absolute;
  left: 50%;
  top: 0;
  height: 0;
  width: 35px;
  background: linear-gradient(172deg, rgba(63, 94, 251, 0.363) 0%, rgba(252, 70, 106, 0.331) 100%);
  transform: translateX(-50%);
  z-index: -1;
  transition: height 5s ease; /* Adjusted animation speed */
}

.animate-line {
  height: 100%; /* Full height for the timeline line */
}

/* Base Styles */
.main-hero {
     
    display: flex;
    background: rgb(0, 154, 228);
    background: linear-gradient(161deg, rgba(0,154,228,0.247) 0%, rgba(255,0,232,0.253) 100%);
    min-height: 100vh;
    padding: 20px;z-index: 0;
}
body{
    overflow-x: hidden;
}
h1 {
    margin-top: 85px;
    font-size: 85px;
    font-weight: 800;margin-left:-15px;
    color: rgb(51, 78, 88);
}

p {
    font-weight: 500;
    font-size: 20px;
    color: #205569;
    margin-top: 20px;
}

.navbar-in-hero{
    z-index: 100;
}


.LR {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 80%;
    margin-top: 5px;
}

.left, .right {
    flex: 1;
}

.right img {
   width: 100%;
    height: auto;
    width: 600px;
    height: 500px;
}
@media (max-width: 660px) {
    .right img {
    width: 450px;
    height: 430px;
    margin-left: -20px;
    }
  
  }
  @media (max-width: 440px) {
    .right img {
    width: 380px;
    height: 370px;
    margin-left: 105px;
    }
  
  }

  @media (max-width: 480px) {
    .right img {
    margin-left: 105px;
    }
}
  @media (max-width: 640px) {
.right_{
    width: 250px;
}
.right_ img{
    width: 460px;
    margin-left: -120px;
}
  }

  @media (max-width: 380px) {
    .right_{
        width: 150px;
    }
    .right_ img{
        width: 376px;
        margin-left: -110px;
    }
      }
  
.contact-button {
    display: inline-block;
    padding: 10px 20px;
    /* border-radius: 20px;  */
    border: 2px solid transparent;  
    background: transparent;  
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, #e254ff, #57d3ff);  
    color: transparent; 
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    margin-top: 20px;
    transition: all 0.3s ease;
    border-image: linear-gradient(90deg, #e254ff, #57d3ff) 1;  
}

.contact-button:hover {
    border: 2px solid transparent;  
    background-image: linear-gradient(90deg, #e254ff, #57d3ff);  
    color: white;  
    background-clip: border-box;  
}

.contact-button::after {
    content: "➔";
    padding-left: 5px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    h1 {
        font-size: 60px;
    }

    p {
        font-size: 18px;
    }

    .LR {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .main-hero {
        flex-direction: column;
        text-align: center;
    }

    .LR {
        flex-direction: column;
    }

    h1 {
        font-size: 48px;
    }

    p {
       padding-top: 20px;
        font-size: 16px;
    }

    .left, .right {
        flex: none;
        width: 100%;
    }

    .right {
        margin-top: 20px;
    }

    .contact-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 36px;
    }

    p {
        font-size: 14px;
    }
.right{
    width: 0px;margin-left: -310px;
    height: auto;
    width: 300px;
}
    .contact-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}

@media (max-width: 750px) {
    .hero {
        flex-direction: column; 
        align-items: center; 
        width: 100;
    }

    .left {
        padding-left: 0;
        text-align: center; 
        margin-right: 0;
        padding-top: 2vh;
        font-size: 2.5vh; 
    }

    .paragraph {
        padding-top: 1vh;
        font-size: 2.5vh; 
        text-align: left; 
    }

    .right {
        margin-right: 0;
        justify-content: center; 
    }

    .contact-button {
        margin-top: 20px; 
        font-size: 14px; 
        padding: 8px 16px; 
    }
} 


.contact-button {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: transparent;  
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(288.34deg, #11B9F9 0.43%, #C209CA 100%); 
    border-radius: 20px;  
    border: 1px solid transparent;  
    border-image-source: linear-gradient(288.34deg, #11B9F9 0.43%, #C209CA 100%); 
   margin-left: -22px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease;  
}

.contact-button:hover,
.contact-button:focus {
    background-image: linear-gradient(288.34deg, #11B9F9 0.43%, #C209CA 100%);  
    color: white;  
    background-clip: padding-box;  
    -webkit-background-clip: padding-box;  
    border: 1px solid transparent;  
    border-image-source: linear-gradient(288.34deg, #11B9F9 0.43%, #C209CA 100%);  
    -webkit-text-fill-color: white;  
}

.contact-button::after {
    content: "➔";  
    padding-left: 5px;
}


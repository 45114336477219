.portfolio-container {
    background: linear-gradient(54deg, rgba(17,19,178,0.16) 0%, rgba(249,18,221,0.15) 100%);
    padding-top: 0px;
}

.portfolio-content {
    min-height: 90vh;
    text-align: center;
}

.portfolio-title {
    margin-top: 60px;
    color: #5D5D5D;
    font-weight: 900;
    font-size: 80px;
}

.filter-buttons {
    margin: 20px 0 60px;
}

.filter-button {
    font-size: 24px;
    color: #5D5D5D;
    margin: 0 25px;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
}

.filter-button::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 3px;
    background: linear-gradient(to right, #007bff, #ff6347);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.filter-button:hover::after, 
.filter-button.active::after {
    transform: scaleX(1);
    transform-origin: left;
}

.filter-button:hover {
    color: #030000;
}

.filter-button.active {
    color: #007bff;
}

@media (max-width: 768px) {
    .portfolio-title {
        font-size: 5vh;
    }

    .filter-button {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .portfolio-title {
        font-size: 4vh;
    }

    .filter-button {
        font-size: 18px;
    }
}